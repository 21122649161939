import { Peril } from '../../../../../Typescript/classes';
import { styled } from '@mui/material';
import { displayPerilRate } from './utility';

interface PerilRateDisplayProps {
  peril: Peril;
}
export const PerilRateDisplay = ({ peril }: PerilRateDisplayProps) => {
  return (
    <>
      {peril.overridePremium && peril.impliedRateDifferent() ? (
        <OverwrittenRateContainer>
          {peril.rate ? (
            <>
              <del>{displayPerilRate(peril.rate)}</del>
              <span>&darr;</span>
            </>
          ) : null}
          <span>{displayPerilRate(peril.getImpliedRate())}</span>
        </OverwrittenRateContainer>
      ) : (
        displayPerilRate(peril.rate)
      )}{' '}
    </>
  );
};

const OverwrittenRateContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
