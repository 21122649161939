// @ts-nocheck
// import { useQuery } from '@apollo/client';
// import { allBusinessTypes } from '../queries';
// types and classes
import type { RouteComponentProps } from '@reach/router';

import { withFormStyles, WithFormStyles } from '../util/withFormStyles';
// @ts-expect-error
import { Form, FormState } from 'informed';

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useField } from 'informed';
import { FormControl, FormLabel, FormHelperText, Slider } from '@mui/material';
import Grid from '@mui/material/Grid';
import NumberFormat from 'react-number-format';
import MaterialTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';

// types and classes
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

interface TestingProps extends RouteComponentProps, WithFormStyles {}
export const Testing = withFormStyles()(({ classes }: TestingProps) => {
  const headers = new Array(15).fill(0).map((_, index) => index);
  const rows = new Array(25)
    .fill(0)
    .map(() => new Array(15).fill(0).map((_, index) => `Test ${index + 1}`));
  return (
    <Table sx={{ overflowX: 'scroll' }}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((elem, elemIndex) => {
              return <TableCell key={elemIndex}>{elem}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

interface LogarithmicSliderProps {
  onChange?: $TSFixMe;
  onBlur?: $TSFixMe;
  helperText?: $TSFixMe;
  field: string;
  max: number;
  min: number;
  prefix?: string;
  suffix?: string;
  className?: $TSFixMe;
  label: string;
  required?: boolean;
  validate?: $TSFixMe;
  validateOnChange?: boolean;
  [k: string]: $TSFixMe;
}

export const InformedLogarithmicSlider = (props: LogarithmicSliderProps) => {
  const {
    onChange,
    onBlur,
    helperText,
    field,
    max,
    min,
    prefix,
    suffix,
    className,
    label,
    required = false,
    validate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateOnChange = true,
    ...otherProps
  } = props;

  const [indicesToShow, setIndicesToShow] = useState<Array<number>>([]);
  const [transformedMin, setTransformedMin] = useState<number>(
    transformRawToLog(min),
  );
  const [transformedMax, setTransformedMax] = useState<number>(
    transformRawToLog(max),
  );
  const [sx, setSx] = useState<object>({});
  const [displayValue, setDisplayValue] = useState<string>('');

  const { fieldState, fieldApi, render } = useField({
    ...props,
    validate: generateActualValidate(required, required ? validate : null), // This logic should be moved to the QuoteComponent! It makes not sense to have it be here
    // @ts-expect-error
    sx, // needs to be here so that updating the marks triggers a re-render
    displayValue,
  });

  const { setValue, setTouched, setError } = fieldApi;
  const { value } = fieldState;

  useEffect(() => {
    setSx(
      indicesToShow.reduce(
        (acc, index) => {
          const key = `& .MuiSlider-mark[data-index="${index}"]`;
          return { ...acc, [key]: { visibility: 'visible' } };
        },
        { '& .MuiSlider-mark': { visibility: 'hidden' } },
      ),
    );
  }, [indicesToShow]);

  // set the display value whenever the value is changed
  useEffect(() => {
    //console.log(`In dispayValue useeffect: ${value}`);
    if (typeof value === 'number') {
      if (convertToNumber(displayValue) !== value) {
        const stringified = formatNumber(value);
        //console.log(`About to set display value to ${stringified}`);
        setDisplayValue(stringified);
      }
    }
  }, [value]);

  // clear the error state whenever the value changes
  useEffect(() => {
    setError(undefined);
  }, [value]);

  // format the marks
  const formatMark = useCallback(
    (newMark: $TSFixMe) => {
      const numbersToText = {
        1e6: '1 Million',
        1e7: '10 Million',
        1e8: '100 Million',
        1e9: '1 Billion',
      };
      return newMark in numbersToText ? (
        // @ts-expect-error
        `${prefix ? prefix : ''}${numbersToText[newMark]}${
          suffix ? ` ${suffix}` : ''
        }`
      ) : (
        // @ts-expect-error
        <NumberFormat
          value={newMark}
          displayType={'text'}
          thousandSeparator
          prefix={prefix ? prefix : null}
          suffix={suffix ? ` ${suffix}` : null}
        />
      );
    },
    [prefix, suffix],
  );

  const generateLabel = useCallback(
    (value: number) => {
      let label;
      if (value === min) {
        label = '0';
      } else if (Number.isInteger(Math.log10(value))) {
        label = formatMark(value);
      } else {
        label = null;
      }
      return label;
    },
    [formatMark, min],
  );

  // ensure that we always have a valid 0
  const transformValue = useCallback(
    (x: number) => (x === transformedMin ? 0 : 10 ** x),
    [transformedMin],
  );

  // set the transformed min
  useEffect(() => {
    setTransformedMin(transformRawToLog(min));
  }, [min]);

  // set the transformed max
  useEffect(() => {
    setTransformedMax(transformRawToLog(max));
  }, [max]);

  // Calculate the marks bases on min and max
  const generateMarks = useCallback(
    (min: number, max: number) => {
      const rawValues = generateRawValues(min, max);
      return rawValues.map((rawValue) => ({
        value: Math.log10(rawValue),
        label: generateLabel(rawValue),
      }));
    },
    [generateLabel],
  );

  const marks = useMemo(
    () => generateMarks(min, max),
    [min, max, generateMarks],
  );

  // set the indices to show when the marks change
  useEffect(() => {
    setIndicesToShow(
      marks.reduce((acc: Array<number>, mark, index) => {
        if (mark.label) {
          acc.push(index);
        }
        return acc;
      }, []),
    );
  }, [marks]);

  return render(
    <>
      <FormControl
        className={className}
        error={!!fieldState.error}
        disabled={otherProps.isDisabled}
        variant='standard'
      >
        <FormLabel htmlFor={field}>{label}</FormLabel>
        <div style={{ paddingLeft: 25 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Slider
                id={field}
                sx={sx}
                size='small'
                value={
                  // @ts-expect-error
                  transformRawToLog(value ? value : 0)
                }
                onChange={(e, tempVal) => {
                  // @ts-expect-error
                  setValue(Math.round(transformValue(tempVal)));
                  onChange && onChange(e);
                }}
                onBlur={(e) => {
                  setTouched(true);
                  onBlur && onBlur(e);
                }}
                {...otherProps}
                min={transformedMin}
                max={transformedMax}
                scale={transformValue}
                marks={marks}
                step={null}
              />
            </Grid>
            <Grid item xs={2}>
              <MaterialTextField
                variant='standard'
                value={displayValue}
                onChange={(e) => {
                  //console.log({ e: e.target.value });
                  const converted = convertToNumber(e.target.value);
                  //console.log({ converted });
                  if (e.target.value === '') {
                    // @ts-expect-error
                    setValue(undefined);
                    setDisplayValue('');
                  } else if (converted === null) {
                    setError(
                      `Unable to convert ${e.target.value} to a valid number.`,
                    );
                    setDisplayValue(e.target.value);
                  } else {
                    setValue(converted);
                    setDisplayValue(e.target.value);
                  }
                  // setDisplayValue(e.target.value);
                }}
                onBlur={() => {
                  let effectiveValue = value;
                  if (effectiveValue > max) {
                    effectiveValue = max;
                  } else if (effectiveValue < min) {
                    effectiveValue = min;
                  }
                  // @ts-expect-error
                  setDisplayValue(formatNumber(effectiveValue));
                }}
                InputProps={{
                  startAdornment: prefix ? (
                    <InputAdornment position='start'>{prefix}</InputAdornment>
                  ) : undefined,
                  endAdornment: suffix ? (
                    <InputAdornment position='end'>{suffix}</InputAdornment>
                  ) : undefined,
                }}
              />

              {/*
              <NumberFormat
                value={value}
                displayType={'text'}
                thousandSeparator
                prefix={prefix ? prefix : null}
                suffix={suffix ? suffix : null}
              />
                */}
            </Grid>
          </Grid>
        </div>
        {fieldState.error && (
          <FormHelperText>{fieldState.error}</FormHelperText>
        )}
      </FormControl>
    </>,
  );
};

export const transformRawToLog = (value: number) =>
  value === 0 ? 0 : Math.log10(value);

/**
 * Get the stepsize for a given number (by what amount should we increase it?)
 */
export const getStepSize = (num: number) => {
  if (num < 100) {
    return 1;
  } else if (num < 1000) {
    return 50;
  } else if (num < 10000) {
    return 500;
  } else if (num < 100000) {
    return 5000;
  } else if (num < 1000000) {
    return 50000;
  } else {
    return 100000;
  }
};

export const generateRawValues = (min: number, max: number) => {
  const marks = [];
  let current = min;
  while (current < max) {
    marks.push(current);
    current += getStepSize(current);
  }
  marks.push(max);
  // check that both 0 and 1 are not in there; if so, remove 1
  if (marks.includes(0) && marks.includes(1)) {
    return marks.filter((mark) => mark !== 1);
  }
  return marks;
};

export const validateNonEmptyOnRequired = (value: any) => {
  return [null, undefined, ''].includes(value) ? 'BOOP' : undefined;
};

/**
 * Generate the 'real' validation function to accomodate the value of the 'required' and 'validate' props
 */
export const generateActualValidate: (
  required: boolean | undefined,
  validate: $TSFixMe,
) => $TSFixMe | undefined = (
  required: boolean | undefined,
  validate: $TSFixMe,
) => {
  return required || validate
    ? (value: any) => {
        if (required) {
          const requiredCheck = validateNonEmptyOnRequired(value);
          if (requiredCheck) {
            return requiredCheck;
          }
        }
        if (validate) {
          const validationCheck = validate(value);
          if (validationCheck) {
            return validationCheck;
          }
        }
        return undefined;
      }
    : undefined;
};

/**
 * Converts it to a number. If the converted number is NaN or some variation on infinity, it returns null instead
 * @param value - The string to be converted
 * @returns - either the numerical version of the passed in string or null if it either can't be converted or if the converted number is NaA or some sort of infinity
 */
const convertToNumber = (value: string): number | null => {
  try {
    if (value === '') {
      return null;
    }
    const converted = Number(value.replace(/[^0-9-.]/g, ''));
    if (Number.isNaN(converted) || !Number.isFinite(converted)) {
      return null;
    }
    return converted;
  } catch (e) {
    console.error(`Error when attempting to convert ${value} to a number`, e);
    return null;
  }
};

const formatNumber = (num: number | undefined) => {
  if (num === undefined) {
    return '';
  }
  return num.toLocaleString();
};
